<template>
  <div class="content-page">
    <div class="content-nav">
      <el-breadcrumb class="breadcrumb" separator="/">
        <el-breadcrumb-item :to="{ name: 'nature' }">品牌商</el-breadcrumb-item>
        <el-breadcrumb-item>{{ infoForm.id ? '编辑品牌商' : '添加品牌商' }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="operation-nav">
        <el-button type="primary" @click="goBackPage" icon="arrow-left">返回列表</el-button>
      </div>
    </div>
    <div class="content-main">
      <div class="form-table-box">
        <el-form ref="infoForm" :rules="infoRules" :model="infoForm" label-width="120px">
          <el-form-item label="分类" prop="name">
            <el-select v-model="infoForm.cate_id" placeholder="请选择分类">
              <el-option
                v-for="item in parentCategory"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="登录名" prop="account">
            <el-input v-model="infoForm.account"></el-input>
          </el-form-item>
          <el-form-item label="登录密码" prop="pwd">
            <el-input v-model="infoForm.pwd"></el-input>
          </el-form-item>

          <el-form-item label="公司名称" prop="company">
            <el-input v-model="infoForm.company"></el-input>
          </el-form-item>
          <el-form-item label="品牌名称" prop="brand">
            <el-input v-model="infoForm.brand"></el-input>
          </el-form-item>

          <el-form-item label="店铺名称" prop="store_name">
            <el-input v-model="infoForm.store_name"></el-input>
          </el-form-item>

          <el-form-item label="一句话简介" prop="brief">
            <el-input v-model="infoForm.brief"></el-input>
          </el-form-item>

          <el-form-item label="详细介绍">
            <el-input type="textarea" v-model="infoForm.desc" :rows="3"></el-input>
            <div class="form-tip"></div>
          </el-form-item>
          <el-form-item label="背景图片" prop="background_img">
            <img v-if="infoForm.background_img" :src="infoForm.background_img" class="image-show" />
            <el-upload
              class="upload-demo"
              name="file"
              :action="qiniuZone"
              :file-list="fileList"
              :on-success="handleUploadBannerSuccess"
              :data="picData"
              :before-upload="getQiniuToken"
            >
              <el-button v-if="!infoForm.background_img" size="small" type="primary"
                >点击上传</el-button
              >
            </el-upload>
            <div class="form-tip">图片尺寸：顶级分类为690*自定义, 只能上传jpg/png文件</div>
          </el-form-item>
          <!-- <el-form-item label="分类图片高度" prop="name" >
                        <el-input v-model="infoForm.p_height"></el-input>
                    </el-form-item> -->
          <el-form-item label="图标" prop="avatar">
            <img v-if="infoForm.avatar" :src="infoForm.avatar" class="image-show" />
            <el-upload
              class="upload-demo"
              name="file"
              :action="qiniuZone"
              :on-remove="iconRemove"
              :file-list="fileList2"
              :data="picData"
              :on-success="handleUploadIconSuccess"
              :before-upload="getQiniuToken"
            >
              <el-button v-if="!infoForm.avatar" size="small" type="primary">点击上传</el-button>
            </el-upload>

            <div class="form-tip">图片尺寸：图标80*80, 只能上传jpg/png文件</div>
          </el-form-item>
          <el-form-item label="排序">
            <el-input-number v-model="infoForm.sort" :min="1"></el-input-number>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmitInfo">确定保存</el-button>
            <el-button @click="goBackPage">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/config/api';
import ElFormItem from 'element-ui/packages/form/src/form-item.vue';

export default {
  data() {
    return {
      root: '',
      qiniuZone: '',
      fileList: [],
      fileList2: [],
      uploaderHeader: {
        'I-WanBo-Token': localStorage.getItem('token') || ''
      },
      parentCategory: [],
      infoForm: {
        id: 0,
        cate_id: '',
        account: '',
        pwd: '',
        company: '',
        brand: '',
        store_name: '',
        avatar: '',
        sort: 1,
        brief: '',
        desc: '',
        background_img: ''
      },
      infoRules: {
        cate_id: [{ required: true, message: '分类未选择', trigger: 'blur' }],
        account: [{ required: true, message: '账户名未填写', trigger: 'blur' }],
        pwd: [{ required: true, message: '密码未填写', trigger: 'blur' }],
        company: [{ required: true, message: '公司名称未填写', trigger: 'blur' }],
        store_name: [{ required: true, message: '店铺名称未填写', trigger: 'blur' }],
        brief: [{ required: true, message: '简介未填写', trigger: 'blur' }]
      },
      picData: {
        token: ''
      },
      url: ''
    };
  },
  methods: {
    getQiniuToken() {
      let that = this;
      this.axios.post('index/getQiniuToken').then((response) => {
        let resInfo = response.data.data;
        console.log(resInfo);
        that.picData.token = resInfo.token;
        that.url = resInfo.url;
      });
    },

    iconRemove(file, fileList) {
      this.infoForm.avatar = '';
      let id = this.infoForm.id;
      this.axios.post('brand/deleteIconImage', { id: id }).then((response) => {
        this.$message({
          type: 'success',
          message: '删除成功'
        });
      });
    },
    goBackPage() {
      this.$router.go(-1);
    },
    onSubmitInfo() {
      this.$refs['infoForm'].validate((valid) => {
        if (valid) {
          this.axios.post('brand/store', this.infoForm).then((response) => {
            if (response.data.errno === 0) {
              this.$message({
                type: 'success',
                message: '保存成功'
              });
              this.$router.go(-1);
            } else {
              this.$message({
                type: 'error',
                message: '保存失败'
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    handleUploadBannerSuccess(res, file) {
      let url = this.url;
      this.infoForm.background_img = url + res.key;
    },
    handleUploadIconSuccess(res, file) {
      let url = this.url;
      this.infoForm.avatar = url + res.key;
    },
    getTopCategory() {
      this.axios.get('brand/brandCateList').then((response) => {
        this.parentCategory = this.parentCategory.concat(response.data.data);
      });
    },
    getInfo() {
      if (this.infoForm.id <= 0) {
        return false;
      }
      //加载分类详情
      let that = this;
      this.axios
        .get('brand/info', {
          params: {
            id: that.infoForm.id
          }
        })
        .then((response) => {
          let resInfo = response.data.data;
          let data = {
            name: '背景图',
            url: resInfo.background_img
          };
          this.fileList.push(data);
          let iconData = {
            name: '图标',
            url: resInfo.avatar
          };
          this.fileList2.push(iconData);
          that.infoForm = resInfo;
        });
    }
  },
  components: { ElFormItem },
  mounted() {
    this.getTopCategory();
    this.infoForm.id = this.$route.query.id || 0;
    this.getInfo();
    this.root = api.rootUrl;
    this.qiniuZone = api.qiniu;
    this.getQiniuToken();
  }
};
</script>

<style scoped>
.image-uploader {
  height: 105px;
}

.image-uploader .el-upload {
  border: 1px solid #d9d9d9;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.image-uploader .el-upload:hover {
  border-color: #20a0ff;
}

.image-uploader .image-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  min-width: 105px;
  height: 105px;
  line-height: 105px;
  text-align: center;
}

.image-show {
  background-color: #f8f8f8;
  min-width: 105px;
  height: 105px;
  display: block;
}
</style>
